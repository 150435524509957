import React from 'react';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { GetServerSideProps } from 'next';
import { NextSeo } from 'next-seo';

import Footer from '@/components/Footer';
import HeaderMenu from '@/components/HeaderMenu';
import MainBanner from '@/components/MainPage/MainBanner';
import RecentPosts from '@/components/MainPage/RecentPosts';
import SubscribeBlock from '@/components/SubscribeBlock';
import PickPosts from '@/components/MainPage/PickPosts';
import TrendingPosts from '@/components/MainPage/TrendingPosts';
import CategoryPosts from '@/components/MainPage/CategoryPosts';
import { handleError } from '@/helpers/handleError';

import { GET_HOMEPAGE_DATA, GET_CATEGORY_POSTS } from '@/graphql/queries';
import { CategoryData } from '@/Types/types';

export default function Home({ categories, header, cookies, homepage, fourLatest, top, trending, footer }: any) {
  const categoriesList: Record<'attributes', CategoryData>[] = categories.data;
  const filteredCategories = Array.of(categories).filter((item: any) => item?.attributes?.categoryPosts?.data?.length);

  return (
    <>
      <HeaderMenu cookies={cookies.data} menu={header[0].items} />
      <NextSeo title={homepage.data.attributes.metaTitle} description={homepage.data.attributes.metaDescription} />
      {Boolean(homepage.data.attributes.articles.data.length) && (
        <MainBanner data={homepage.data.attributes.articles.data} />
      )}
      <RecentPosts
        data={fourLatest.data}
        sponsorImg={homepage.data.attributes.sponsorImg.data.attributes.url}
        sponsorLink={homepage.data.attributes.sponsorLink}
        topics={categoriesList}
      />
      {Boolean(top.data.length) && <PickPosts item={top.data} />}
      {filteredCategories.map((item: any, index: number) => (
        <React.Fragment key={index}>
          <CategoryPosts
            category={item?.attributes?.title}
            url={item?.attributes?.slug}
            articles={item?.attributes?.categoryPosts?.data}
          />
          {(index === filteredCategories.length % 2 || filteredCategories.length === 1) &&
            Boolean(trending.data.length) && <TrendingPosts item={trending.data} />}
        </React.Fragment>
      ))}
      {Boolean(!filteredCategories.length) && Boolean(trending.data.length) && <TrendingPosts item={trending.data} />}

      <SubscribeBlock />
      <Footer menu={footer[0].items} />
    </>
  );
}

export const getServerSideProps: GetServerSideProps = async () => {
  try {
    const client = new ApolloClient({
      uri: `${process.env.NEXT_PUBLIC_API_URL}/graphql`,
      cache: new InMemoryCache(),
    });

    const { data: homepageData } = await client.query({
      query: GET_HOMEPAGE_DATA,
    });

    const { data: categoryArticlesData } = await client.query({
      query: GET_CATEGORY_POSTS,
    });

    return {
      props: {
        ...homepageData,
        ...categoryArticlesData,
      },
    };
  } catch (error) {
    handleError(error, 'Index getServerSideProps');

    return {
      notFound: true,
    };
  }
};
